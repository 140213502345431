import { useMediaQuery } from "@mui/material";
import React, { useContext } from "react";

import { Footer } from "../../components/Footer/Footer";
import { OverFooter } from "../../components/OverFooter/OverFooter";
import { InsideZenityPageContext } from "../../helpers/pagesContext";
import { FORMATION } from "../../helpers/route";
import { Business } from "./components/Business/Business";
import { Carousel } from "./components/Carousel/Carousel";
import { Contributor } from "./components/Contributor/Contributor";
import { Events } from "./components/Events/Events";
import { Hero } from "./components/Hero/Hero";
import { Partner } from "./components/Partner/Partner";
import * as styles from "./InsideZenity.module.css";

export default function Homepage() {
  const { footer, node_locale, events, footerTitle, footerLabelTitle } =
    useContext(InsideZenityPageContext);

  const sm = useMediaQuery("(max-width:  767.98px)");

  return (
    <div>
      <Hero />
      <Business />
      <Partner />
      <div className={styles.image}>
        {sm ? <Carousel /> : <Contributor />}
        <div className={styles.wrap}>
          <div className={styles.content}>
            <div className={styles.item}>
              <div className={styles.sticky}>{events}</div>
            </div>
          </div>
          <Events />
        </div>
      </div>
      <OverFooter
        title={footerTitle}
        buttonLink={FORMATION}
        buttonLabel={footerLabelTitle}
        image={footer.file.url}
      >
        <Footer lang={node_locale} />
      </OverFooter>
    </div>
  );
}
