import { graphql } from "gatsby";
import React from "react";

import Layout from "../components/Layout";
import Seo from "../components/seo";
import { InsideZenityPageContext } from "../helpers/pagesContext";
import { INSIDE_ZENITY } from "../helpers/route";
import InsideZenityScene from "../scenes/InsideZenity/InsideZenity";

export default function InsideZenity(props) {
  return (
    <Layout
      navbarTheme="dark"
      currentRoute={INSIDE_ZENITY}
      data={props.data.contentfulMenuLabel}
    >
      <Seo
        title="Inside Zenity"
        description="Les clients sont satisfaits de Zenity pour la qualité de ses ressources, une expertise pionnière et son centre de formation, sa capacité à placer l'humain au centre de la collaboration"
      />
      <InsideZenityPageContext.Provider
        value={props.data.contentfulInsideZenity}
      >
        <InsideZenityScene />
      </InsideZenityPageContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($locale: String!) {
    contentfulInsideZenity(node_locale: { eq: $locale }) {
      rotatingText
      matchCvCta
      contactUsCta
      businessTitle {
        raw
      }
      businessReason {
        title
        mainTitle
        reason {
          raw
        }
      }
      hero {
        gatsbyImageData(placeholder: BLURRED)
      }
      partnerTestimonials {
        id
        title
        name
        job
        sentence {
          childMdx {
            body
          }
        }
        image {
          gatsbyImageData(placeholder: BLURRED)
          description
        }
      }
      partnerBrand {
        id
        gatsbyImageData(placeholder: BLURRED, width: 235)
        file {
          fileName
        }
      }
      contributor {
        id
        title
        subtitle
        seniority
        seniorityDate
        node_locale
        quote {
          quote
        }
        image {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      event {
        youtubeUrl
        thumbnail {
          gatsbyImageData(width: 780, height: 400, placeholder: BLURRED)
        }
      }
      footer {
        file {
          url
        }
      }
      node_locale
      believeInStars
      explorationStory
      events
      canopy
      footerTitle
      footerLabelTitle
      year
      atZenity
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;
