import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";

import { YOUTUBE } from "../../helpers/constants";
import { PlayerButton } from "../Button/Button";
import * as styles from "./ModalViewer.module.css";

const getEndpointOfUrl = (url) => {
  if (url) {
    const splitedUrl = url.split("/");
    const endpoint = splitedUrl[splitedUrl.length - 1];
    return endpoint.replace("watch?v=", "");
  }
};

const isYoutube = (url) => url.includes(YOUTUBE);

export const ModalViewer = ({
  image,
  video,
  url = null,
  overlay = false,
  setStack = false,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setStack && setStack(false);
  };

  return (
    <div className={styles.content}>
      {open ? (
        <iframe
          className={styles.video}
          src={
            url
              ? isYoutube(url)
                ? `${
                    url
                      ? `https://www.youtube.com/embed/${getEndpointOfUrl(url)}`
                      : video
                  }?showinfo=0&autoplay=1&modestbranding=1&fs=0&rel=0&`
                : url
              : isYoutube(video)
              ? `${
                  video
                    ? `https://www.youtube.com/embed/${getEndpointOfUrl(video)}`
                    : video
                }?showinfo=0&autoplay=1&modestbranding=1&fs=0&rel=0&`
              : video
          }
          title="Video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <>
          <GatsbyImage
            image={image}
            alt="Player video"
            className={styles.images}
          />
          <div className={styles.button}>
            <PlayerButton stroke="#fff" onClick={handleOpen} />
          </div>
          {overlay && <div className={styles.overlay} />}
        </>
      )}
    </div>
  );
};
