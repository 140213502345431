import "moment/locale/fr";

import clsx from "clsx";
import { GatsbyImage } from "gatsby-plugin-image";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { InsideZenityPageContext } from "../../../../../helpers/pagesContext";
import * as styles from "./ContributorItem.module.css";

export const ContributorItem = ({
  image,
  title,
  subtitle,
  seniorityDate,
  node_locale,
  quote,
  position,
}) => {
  const { atZenity } = useContext(InsideZenityPageContext);

  if (node_locale === "fr-FR") moment.locale("fr");
  else moment.locale("en");
  const date = moment(seniorityDate);

  return (
    <>
      <div className={styles.root}>
        <GatsbyImage
          className={styles.image}
          image={image.gatsbyImageData}
          alt="Contributor item"
        />
        <div
          className={clsx(
            styles.hide,
            position % 2 === 0 ? styles.blue : styles.gold
          )}
        >
          <div className={styles.text}>
            <div className={styles.quote}>{quote}</div>
            <div className={styles.subtitle}>{`${title}, ${subtitle}`}</div>
            <div className={styles.title}>
              {`${atZenity} ${date.fromNow(true)}`}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ContributorItem.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
};
