import React, { useState } from "react";
import { useEffect } from "react";

import * as styles from "./rotatingText.module.css";

export const RotatingText = ({ text }) => {
  const baseText = text;
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const pos = position > baseText.length - 2 ? 0 : position + 1;

      setPosition(pos);
    }, 2500);

    return () => clearInterval(interval);
  }, [position]);

  return (
    position !== null && (
      <div className={styles.root} key={position}>
        {baseText[position]}
      </div>
    )
  );
};
