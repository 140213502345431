import React, { useContext } from "react";

import { ModalViewer } from "../../../../components/ModalViewer/ModalViewer";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { InsideZenityPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Events.module.css";

export const Events = () => {
  const { event, canopy } = useContext(InsideZenityPageContext);
  return (
    <section className={styles.root}>
      <div className={styles.title}>
        <SlipTransition lines={[canopy]} />
      </div>
      <div className={styles.container}>
        <SlideTransition delay={0.2}>
          <div className={styles.content}>
            <ModalViewer
              image={event.thumbnail.gatsbyImageData}
              video={event.youtubeUrl}
            />
          </div>
        </SlideTransition>
      </div>
    </section>
  );
};
