import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";

import { InsideZenityPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Hero.module.css";

export const Hero = () => {
  const { hero } = useContext(InsideZenityPageContext);

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <GatsbyImage
            className={styles.image}
            image={hero.gatsbyImageData}
            alt="hero"
          />
        </div>
        <h1 className={styles.textInside}>Inside</h1>
        <div className={styles.textZenity}>
          Zenit<span>y</span>
        </div>
      </div>
    </section>
  );
};
