import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import { Closure } from "../../components/Closure/Closure";
import animationGif from "../../resources/images/match-your-cv/animation.gif";
import animationCheckedGif from "../../resources/images/match-your-cv/animationChecked.gif";
import cloudGold from "../../resources/images/match-your-cv/cloudGold.svg";
import cloudGrey from "../../resources/images/match-your-cv/cloudGrey.svg";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import * as styles from "./MatchYourBusiness.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} timeout={600} />;
});

export default function MatchYourBusiness({
  open,
  handleClose,
  job,
  lang = "fr-FR",
}) {
  const [hoverDropzone, setHoverDropzone] = useState(false);
  const [formFiles, setformFiles] = useState([]);
  const [checkbox, setCheckbox] = useState(false);
  const [send, setSend] = useState(false);
  const [sendAnimation, setSendAnimation] = useState(false);
  const [doneAnimation, setDoneAnimation] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      formFiles.length < 2 && setformFiles([...formFiles, ...acceptedFiles]);
    },
    [formFiles]
  );

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    noKeyboard: true,
    onDrop,
    maxFiles: 2,
    maxSize: 4000000,
    accept:
      "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  });

  const loadAnimation = () => {
    setSend(true);
    setSendAnimation(true);
    setTimeout(function () {
      setSendAnimation(false);
      setDoneAnimation(true);
    }, 3750);
    setTimeout(function () {
      handleReset();
    }, 13750);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    loadAnimation();

    const formdata = new FormData();

    formFiles.forEach((fileInput) => {
      formdata.append("cv", fileInput, fileInput.name);
    });

    try {
      const result = await fetch("/api/contact/business", {
        method: "POST",
        body: formdata,
      });
      await result.json();
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickCheckbox = () => {
    setCheckbox(!checkbox);
  };

  const handleReset = () => {
    setformFiles([]);
    setDoneAnimation(false);
    setSend(false);
  };

  const remove = (file) => {
    setformFiles(formFiles.filter((_, idx) => idx !== file));
  };

  const handleClosePrivacyPolicy = () => setOpenPrivacyPolicy(false);

  lang = lang === "en-US" ? "en" : "fr";
  const data = useStaticQuery(
    graphql`
      {
        fr: contentfulResume(node_locale: { eq: "fr-FR" }) {
          title
          titleBusiness
          titlePartTwoBusiness
          post
          wellReceived
          wellReceivedTwo
          policyBusinessOne
          policyBusinessTwo
          chooseFile
          drop
          formLoading
          formResponseOne
          formResponseTwo
          formTitle
          id
          contentful_id
          node_locale
          policyOne
          policyTwo
          send
          size
          spaceId
          subtitle
          title
          titlePartTwo
          tooltypeOne
          tooltypeThree
          tooltypeTwo
          updatedAt
          zenity
        }
        en: contentfulResume(node_locale: { eq: "en-US" }) {
          title
          titleBusiness
          titlePartTwoBusiness
          post
          wellReceived
          wellReceivedTwo
          policyBusinessOne
          policyBusinessTwo
          chooseFile
          drop
          formLoading
          formResponseOne
          formResponseTwo
          formTitle
          id
          contentful_id
          node_locale
          policyOne
          policyTwo
          send
          size
          spaceId
          subtitle
          title
          titlePartTwo
          tooltypeOne
          tooltypeThree
          tooltypeTwo
          updatedAt
          zenity
        }
      }
    `
  );

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        onClose={handleClose}
        open={open}
        fullScreen
        scroll="body"
        sx={{ zIndex: 1302 }}
      >
        <Closure handleClose={handleClose} />
        <div className={clsx(styles.root, styles.startBg)}>
          <div className={styles.container}>
            <h2 className={styles.title}>{data[lang].titleBusiness}</h2>
            <p className={styles.subtitle}>{data[lang].titlePartTwoBusiness}</p>
            <div style={{ maxWidth: 700, width: "100%" }}>
              <form className={styles.fileContainer} onSubmit={handleSubmit}>
                {job && (
                  <div className={styles.jobOpportunity}>
                    <span>{data[lang].formTitle}</span>
                    <span>|</span>
                    <span className={styles.job}>{job}</span>
                  </div>
                )}
                <div
                  onMouseEnter={() => setHoverDropzone(true)}
                  onMouseLeave={() => setHoverDropzone(false)}
                  className={clsx(
                    styles.dropzone,
                    isDragAccept && styles.acceptStyle,
                    isDragReject && styles.rejectStyle,
                    send && styles.done
                  )}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  {send ? (
                    <div>
                      {sendAnimation && (
                        <div
                          className={clsx(
                            styles.animationHead,
                            styles.animationHeadSend
                          )}
                        >
                          <img src={animationGif} alt="animation" width={205} />
                          <p className={styles.animationText}>
                            {data[lang].post}
                          </p>
                        </div>
                      )}
                      {doneAnimation && (
                        <div className={styles.animationHead}>
                          <img
                            src={animationCheckedGif}
                            alt="animation check"
                            width={175}
                          />
                          <p className={styles.animationText}>
                            {data[lang].wellReceived}
                            <br />
                            {data[lang].wellReceivedTwo}
                          </p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <img
                        src={hoverDropzone ? cloudGold : cloudGrey}
                        alt="cloud"
                        width="125"
                        className={styles.imageDropzone}
                      />
                      {!isDragReject ? (
                        formFiles.length > 0 ? (
                          <div>
                            <ul className={styles.listFiles}>
                              {formFiles.map(({ name }, index) => {
                                return (
                                  <li key={index}>
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        remove(index);
                                      }}
                                    >
                                      <StaticImage
                                        src="../../resources/images/match-your-cv/btn-delete.png"
                                        width={30}
                                        alt="delete"
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                    {name}
                                  </li>
                                );
                              })}
                            </ul>
                            {fileRejections.length > 0 && (
                              <p className={styles.errorText}>
                                {fileRejections.at(0).errors.at(0).code ===
                                "file-invalid-type"
                                  ? data[lang].drop
                                  : data[lang].tooltypeOne}
                              </p>
                            )}
                          </div>
                        ) : fileRejections.length > 0 ? (
                          <p className={styles.errorText}>
                            {fileRejections.at(0).errors.at(0).code ===
                            "file-invalid-type"
                              ? data[lang].drop
                              : data[lang].tooltypeOne}
                          </p>
                        ) : (
                          <div className={styles.textDropzone}>
                            <p>{data[lang].tooltypeTwo}</p>
                            <p>{data[lang].tooltypeThree}</p>
                          </div>
                        )
                      ) : (
                        <div className={styles.rejectDropzone}>
                          <p className={styles.errorText}>{data[lang].drop}</p>
                        </div>
                      )}
                      {formFiles.length === 1 && (
                        <div className={styles.addItem}>{data[lang].size}</div>
                      )}
                      {formFiles.length > 0 ? (
                        <button
                          className={clsx(
                            styles.button,
                            !checkbox && styles.buttonDisabled
                          )}
                          disabled={!checkbox}
                          onClick={(e) => e.stopPropagation()}
                          type="submit"
                        >
                          {data[lang].send}
                        </button>
                      ) : (
                        <div className={styles.button}>
                          {data[lang].chooseFile}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className={styles.input}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      style={{ margin: "0 5px 0 0" }}
                      type="checkbox"
                      id="terms"
                      name="terms"
                      required
                      onClick={() => handleClickCheckbox()}
                    />
                    <label
                      htmlFor="terms"
                      className={clsx(
                        !checkbox && formFiles.length > 0 && styles.label
                      )}
                    >
                      {`${data[lang].policyOne} `}
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenPrivacyPolicy(true);
                        }}
                        className={styles.underline}
                      >
                        {data[lang].policyTwo}
                      </span>
                      .
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={styles.bottom}>
            {data[lang].zenity} {new Date().getFullYear()}
          </div>
        </div>
      </Dialog>
      <PrivacyPolicy
        open={openPrivacyPolicy}
        handleClose={handleClosePrivacyPolicy}
      />
    </>
  );
}
