import "moment/locale/fr";

import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { InsideZenityPageContext } from "../../../../../helpers/pagesContext";
import * as styles from "./CarouselItem.module.css";

export const CarouselItem = ({
  title,
  subtitle,
  seniorityDate,
  quote,
  position,
  node_locale,
}) => {
  const { atZenity } = useContext(InsideZenityPageContext);

  if (node_locale === "fr-FR") moment.locale("fr");
  else moment.locale("en");
  const date = moment(seniorityDate);

  return (
    <div className={styles.item}>
      <div
        className={clsx(
          styles.background,
          position % 2 === 0 ? styles.blue : styles.gold
        )}
      />
      <div className={styles.quote}>{quote}</div>
      <div className={styles.data}>
        <div className={styles.title}>{`${title}, ${subtitle}`}</div>
        <div className={styles.seniority}>
          {`${atZenity} ${date.fromNow(true)}`}
        </div>
      </div>
    </div>
  );
};

CarouselItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  seniority: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
};
