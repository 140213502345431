import { MDXProvider } from "@mdx-js/react";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import clsx from "clsx";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import Marquee from "react-fast-marquee";
import SwipeableViews from "react-swipeable-views";

import { Arrows } from "../../../../components/Arrows/Arrows";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { InsideZenityPageContext } from "../../../../helpers/pagesContext";
import vectorPicture from "../../../../resources/images/inside-zenity/partner/vector.svg";
import * as styles from "./Partner.module.css";

const AntTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
  "& > div": {
    display: "flex",
    justifyContent: "center",
  },
  "& > div > div > button:first-of-type": {
    marginRight: 25,
  },
  ["@media (max-width: 575.98px)"]: {
    "& > div > div > button:first-of-type": {
      marginRight: "inherit",
    },
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(() => ({
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "19px",
  letterSpacing: "0.26em",
  color: "#2f3f458a",
  fontFamily: "Futura",
  marginLeft: 35,
  marginRight: 35,
  paddingBottom: 35,
  ["@media (max-width: 575.98px)"]: {
    fontSize: 14,
    paddingTop: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
  },
  width: "fit-content",
  "&.Mui-selected": {
    fontWeight: 800,
    color: "#2F3F45",
    ["@media (max-width: 575.98px)"]: {
      color: "#CEA463",
    },
  },
  "&.Mui-selected::after": {
    content: `""`,
    width: 20,
    height: 18,
    background: `url(${vectorPicture}) no-repeat`,
    backgroundSize: "contain",
    position: "absolute",
    bottom: 7,
    left: "50%",
    transform: "translateX(-55%)",
    ["@media (max-width: 575.98px)"]: {
      width: 0,
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ContentDraw = ({ paragraph, name, work, children }) => {
  return (
    <div className={styles.content}>
      <div className={styles.item}>
        <div className={styles.image}>
          <SlideTransition delay={0.4} thresholds={200}>
            <StaticImage
              src="../../../../resources/images/inside-zenity/partner/vector.png"
              alt="vector"
            />
          </SlideTransition>
          {children}
        </div>
        <div className={styles.text}>
          <MDXProvider>
            <article className={styles.paragraph}>
              <MDXRenderer>{paragraph}</MDXRenderer>
            </article>
          </MDXProvider>
          <div className={styles.sign}>
            <div className={styles.name}>{name}</div>
            <div className={styles.work}>{work}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Partner = () => {
  const { partnerTestimonials, partnerBrand, believeInStars } = useContext(
    InsideZenityPageContext,
  );

  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setTimeout(() => {
      setValue(index);
    }, 250);
  };

  return (
    <section className={styles.root}>
      <div className={styles.wrap}>
        <SlipTransition lines={[believeInStars]} />
        <div className={styles.wrapper}>
          <div className={styles.element}>
            <AntTabs
              value={value}
              onChange={handleChange}
              aria-label="ant example"
            >
              {partnerTestimonials.map((item, index) =>
                value === index ? (
                  <AntTab
                    key={`AntTab-${index}`}
                    label={item.title}
                    {...a11yProps(index)}
                  />
                ) : value === index + 1 || value === index - 1 ? (
                  <AntTab
                    key={`AntTab-${index}`}
                    className={clsx(
                      value === index + 1 && styles.rightElement,
                      value === index - 1 && styles.leftElement,
                    )}
                    label={item.title}
                    {...a11yProps(index)}
                  />
                ) : value === 0 && index === partnerTestimonials.length - 1 ? (
                  <AntTab
                    key={`AntTab-${index}`}
                    className={styles.rightElement}
                    label={
                      partnerTestimonials[partnerTestimonials.length - 1].title
                    }
                    {...a11yProps(partnerTestimonials.length - 1)}
                  />
                ) : value === partnerTestimonials.length - 1 && index === 0 ? (
                  <AntTab
                    key={`AntTab-${index}`}
                    className={styles.leftElement}
                    label={partnerTestimonials[0].title}
                    {...a11yProps(0)}
                  />
                ) : (
                  <div key={`AntTab-${index}`} style={{ display: "none" }}>
                    <AntTab label={item.title} />
                  </div>
                ),
              )}
            </AntTabs>
          </div>
          <SwipeableViews
            index={value}
            onChangeIndex={handleChangeIndex}
            enableMouseEvents
            springConfig={{
              duration: "0.5s",
              easeFunction: "ease-out",
              delay: "0s",
            }}
            className={styles.pointer}
          >
            {partnerTestimonials.map((item, index) => (
              <TabPanel key={`£TabPanel-${index}`} value={value} index={index}>
                <ContentDraw
                  title={item.title}
                  paragraph={item.sentence.childMdx.body}
                  name={item.name}
                  work={item.job}
                >
                  <GatsbyImage image={item.image.gatsbyImageData} alt="main" />
                </ContentDraw>
              </TabPanel>
            ))}
          </SwipeableViews>
          <div className={styles.arrow}>
            <Arrows
              value={value}
              setValue={setValue}
              max={partnerTestimonials.length}
            />
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <Marquee pauseOnHover={true} speed={80}>
          {partnerBrand.map((data, i) => (
            <div key={`${i}-logo`} className={styles.partner}>
              <div className={styles.partnerContent}>
                <GatsbyImage
                  image={data.gatsbyImageData}
                  alt={data.file.fileName}
                />
              </div>
            </div>
          ))}
        </Marquee>
      </div>
    </section>
  );
};
