/* eslint-disable import/no-unresolved */
import React, { useContext } from "react";

import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { InsideZenityPageContext } from "../../../../helpers/pagesContext";
import { ContributorItem } from "./components/ContributorItem";
import * as styles from "./Contributor.module.css";

export const Contributor = () => {
  const { contributor, explorationStory } = useContext(InsideZenityPageContext);

  return (
    <section className={styles.root}>
      <div className={styles.title}>
        <SlipTransition lines={[explorationStory]} />
      </div>
      <div className={styles.container}>
        <div className={styles.content}>
          {contributor.map(
            (
              {
                id,
                title,
                image,
                subtitle,
                seniority,
                seniorityDate,
                node_locale,
                quote: { quote },
              },
              i
            ) => {
              return (
                <ContributorItem
                  key={id}
                  image={image}
                  title={title}
                  subtitle={subtitle}
                  seniority={seniority}
                  quote={quote}
                  seniorityDate={seniorityDate}
                  node_locale={node_locale}
                  position={i}
                />
              );
            }
          )}
        </div>
      </div>
    </section>
  );
};
