/* eslint-disable import/no-unresolved */
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { InsideZenityPageContext } from "../../../../helpers/pagesContext";
import * as styles from "./Carousel.module.css";
import { CarouselItem } from "./components/CarouselItem";

export const Carousel = () => {
  const { contributor, explorationStory } = useContext(InsideZenityPageContext);

  const swiperSettings = {
    grabCursor: true,
    spaceBetween: 15,
    slidesPerView: 1,
    slidesPerGroup: 1,
  };

  return (
    <section className={styles.root}>
      <div className={styles.title}>
        <SlipTransition lines={[explorationStory]} />
      </div>
      <div className={styles.container}>
        <Swiper style={{ minHeight: 400 }} {...swiperSettings}>
          {contributor.map(
            (
              {
                id,
                title,
                subtitle,
                seniorityDate,
                node_locale,
                quote: { quote },
              },
              i,
            ) => (
              <SwiperSlide key={id}>
                <SlideTransition thresholds={200}>
                  <CarouselItem
                    title={title}
                    subtitle={subtitle}
                    seniorityDate={seniorityDate}
                    node_locale={node_locale}
                    quote={quote}
                    position={i}
                  />
                </SlideTransition>
              </SwiperSlide>
            ),
          )}
        </Swiper>
        <StaticImage
          src="../../../../resources/images/inside-zenity-stories/background.avif"
          alt="background"
          className={styles.background}
        />
      </div>
    </section>
  );
};
