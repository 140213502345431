// extracted by mini-css-extract-plugin
export var arrow = "Partner-module--arrow--933be";
export var container = "Partner-module--container--1eda6";
export var content = "Partner-module--content--cadcc";
export var element = "Partner-module--element--318ae";
export var image = "Partner-module--image--097d9";
export var item = "Partner-module--item--e4078";
export var leftElement = "Partner-module--leftElement--09c4d";
export var name = "Partner-module--name--fc1db";
export var paragraph = "Partner-module--paragraph--6614f";
export var partner = "Partner-module--partner--8025e";
export var partnerContent = "Partner-module--partnerContent--996b9";
export var photobanner = "Partner-module--photobanner--de5c6";
export var pointer = "Partner-module--pointer--3abc6";
export var rightElement = "Partner-module--rightElement--5ea6e";
export var root = "Partner-module--root--367c3";
export var sign = "Partner-module--sign--2110e";
export var text = "Partner-module--text--ebbc0";
export var work = "Partner-module--work--5a7f3";
export var wrap = "Partner-module--wrap--04e23";
export var wrapper = "Partner-module--wrapper--61e9a";