// extracted by mini-css-extract-plugin
export var acceptStyle = "MatchYourBusiness-module--acceptStyle--ba562";
export var addItem = "MatchYourBusiness-module--addItem--955b2";
export var animationHead = "MatchYourBusiness-module--animationHead--bdbbd";
export var animationHeadSend = "MatchYourBusiness-module--animationHeadSend--a26a1";
export var animationText = "MatchYourBusiness-module--animationText--40d91";
export var bottom = "MatchYourBusiness-module--bottom--a8cf2";
export var button = "MatchYourBusiness-module--button--15da3";
export var buttonDisabled = "MatchYourBusiness-module--buttonDisabled--4d1a7";
export var container = "MatchYourBusiness-module--container--2e2e9";
export var done = "MatchYourBusiness-module--done--e3969";
export var dropzone = "MatchYourBusiness-module--dropzone--85529";
export var errorText = "MatchYourBusiness-module--errorText--25838";
export var fileContainer = "MatchYourBusiness-module--fileContainer--f67fe";
export var imageDropzone = "MatchYourBusiness-module--imageDropzone--cc06c";
export var input = "MatchYourBusiness-module--input--6baf1";
export var itemAdded = "MatchYourBusiness-module--itemAdded--bd48e";
export var job = "MatchYourBusiness-module--job--a82e6";
export var jobOpportunity = "MatchYourBusiness-module--jobOpportunity--4829d";
export var label = "MatchYourBusiness-module--label--bce96";
export var listFiles = "MatchYourBusiness-module--listFiles--f6047";
export var rejectDropzone = "MatchYourBusiness-module--rejectDropzone--964a0";
export var rejectStyle = "MatchYourBusiness-module--rejectStyle--3ec1c";
export var root = "MatchYourBusiness-module--root--13f1e";
export var send = "MatchYourBusiness-module--send--b9ae2";
export var startBg = "MatchYourBusiness-module--startBg--2cd00";
export var subtitle = "MatchYourBusiness-module--subtitle--cfd5f";
export var text = "MatchYourBusiness-module--text--01b77";
export var textDropzone = "MatchYourBusiness-module--textDropzone--9881b";
export var title = "MatchYourBusiness-module--title--4f7c3";
export var underline = "MatchYourBusiness-module--underline--91136";