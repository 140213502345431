// extracted by mini-css-extract-plugin
export var active = "business-module--active--9526b";
export var container = "business-module--container--e7417";
export var counter = "business-module--counter--eb3f4";
export var counterContainer = "business-module--counterContainer--66de7";
export var data = "business-module--data--ea3f0";
export var dataContainer = "business-module--dataContainer--26c09";
export var footerPresentation = "business-module--footerPresentation--bf153";
export var mainTitle = "business-module--mainTitle--e637a";
export var mainTitleText = "business-module--mainTitleText--f5fb9";
export var position = "business-module--position--b359d";
export var reasons = "business-module--reasons--0c6a9";
export var root = "business-module--root--a61fc";
export var rotatingText = "business-module--rotatingText--6e093";
export var simpleBorderButton = "business-module--simpleBorderButton--f8c9c";
export var simpleBorderButtonSafari = "business-module--simpleBorderButtonSafari--c864d";
export var text = "business-module--text--b6c86";
export var title = "business-module--title--da962";
export var titleCTA = "business-module--titleCTA--168c1";
export var titleText = "business-module--titleText--ad986";