import clsx from "clsx";
import { navigate } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { useContext, useState } from "react";

import { BorderButton } from "../../../../components/Button/Button";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { isSafari } from "../../../../helpers/isSafari";
import { localeLink } from "../../../../helpers/localeLink";
import { InsideZenityPageContext } from "../../../../helpers/pagesContext";
import { OPPORTUNITY } from "../../../../helpers/route";
import add from "../../../../resources/images/inside-zenity/add.svg";
import minus from "../../../../resources/images/inside-zenity/minus.svg";
import MatchYourBusiness from "../../../MatchYourBusiness/MatchYourBusiness";
import * as styles from "./business.module.css";
import { RotatingText } from "./components/RotatingText";

export const Business = () => {
  const [active, setActive] = useState(null);
  const [openMatchYourResume, setOpenMatchYourResume] = useState(false);
  const {
    businessTitle,
    businessReason,
    rotatingText,
    node_locale,
    matchCvCta,
    contactUsCta,
  } = useContext(InsideZenityPageContext);

  const handleCloseMatchYourResume = () => {
    setOpenMatchYourResume(false);
  };

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.mainTitle}>
          <div className={styles.counterContainer}>
            <SlideTransition thresholds={300} delay={0.1}>
              <div className={styles.counter}>{businessReason.length}</div>
            </SlideTransition>
          </div>
          <div className={styles.mainTitleText}>
            <div className={styles.text}>{renderRichText(businessTitle)}</div>
            <div className={styles.rotatingText}>
              <RotatingText text={rotatingText} />
            </div>
          </div>
        </div>
        <div className={styles.reasons}>
          {businessReason.map((data, i) => {
            return (
              <SlideTransition
                key={data.title}
                thresholds={100}
                delay={0.2 + i * 0.1}
              >
                <div
                  className={clsx(
                    active === i && styles.active,
                    styles.dataContainer
                  )}
                >
                  <div
                    className={styles.title}
                    onClick={() => {
                      setActive(active === i ? false : i);
                    }}
                  >
                    <div className={styles.position}>{`${i < 9 ? 0 : ""}${
                      i + 1
                    }`}</div>
                    <div className={styles.titleText}>{data.mainTitle}</div>
                    <img
                      className={styles.titleCTA}
                      src={active === i ? minus : add}
                      alt="cta"
                    />
                  </div>
                  <div className={styles.data}>
                    {renderRichText(data.reason)}
                  </div>
                </div>
              </SlideTransition>
            );
          })}
        </div>
        <div className={styles.footerPresentation}>
          <div
            className={styles.simpleBorderButton}
            onClick={() => setOpenMatchYourResume(true)}
          >
            {matchCvCta}
          </div>
          {isSafari() ? (
            <div
              className={styles.simpleBorderButtonSafari}
              onClick={() => {
                navigate(localeLink(OPPORTUNITY));
              }}
            >
              {contactUsCta}
            </div>
          ) : (
            <BorderButton
              label={contactUsCta}
              wide={true}
              width={6}
              responsive={false}
              onClick={() => {
                navigate(localeLink(OPPORTUNITY));
              }}
            />
          )}
        </div>
      </div>
      <MatchYourBusiness
        open={openMatchYourResume}
        handleClose={handleCloseMatchYourResume}
        label={"label"}
        lang={node_locale}
      />
    </section>
  );
};
